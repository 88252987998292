@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Ubuntu', sans-serif;
}

:root {
    /* colors  */
    --bg: #fffbf2;
    --bg-light: #f2f2f2;
    --primary: #A020F0;
    --primary-dark: #6703a5;
    --primary-light: #CBC3E3;
    --secondary: #5bd6a9;
    --text-dark: #202020;
    --text-light: #505050;
    --white: #fff;
    --black: #000000;
    --black-light: #d4d4d4;
    --special-btn: #cfcfcf;
    --testimonial : #e7e6e6;

    /* transitions  */

    --trsn1: all 0.1s ease-in-out;
    --trsn2: all 0.2s ease-in-out;
    --trsn3: all 0.3s ease-in-out;
    --trsn4: all 0.4s ease-in-out;
    --trsn5: all 0.5s ease-in-out;

}

/* Dark theme */

.dark-theme {
    /* colors  */
    --bg: #1c1c29;
    --bg-light: #232330;
    --primary: #A020F0;
    --primary-dark: #6703a5;
    --primary-light: #232330;
    --secondary: #5bd6a9;
    --text-dark: #f2f2f2;
    --text-light: #999999;
    --white: #fff;
    --black: #000000;
    --black-light: #606060;
    --special-btn: #7c7c7c;
}

body {
    background: var(--bg);
    overflow-x: hidden;
}

body .dark-theme {
    background: var(--bg);
}

/* main heading styling  */

.main-heading {
    position: relative;
    text-align: center;
    margin: 40px 0;
    font-weight: 400;
    color: var(--text-dark);
}

.main-heading::before {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 240px;
    height: 2px;
    background: var(--primary);
}

/* hr styling  */

.main-hr1 {
    border: none;
    background: var(--black-light);
    height: 1px;
}


/* Navbar styling  */

.main-navbar{
    position: fixed;
    z-index: 999;
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    background-color: var(--bg-light);
    padding: 20px 0;
    z-index: 999;
}

/* logo  */

.logo {
    width: max-content;
    height: max-content;
    margin-left: 20px;
}

.logo h2 {
    font-size: 30px;
    color: var(--text-dark);
}

.logo h2>span {
    color: var(--primary);
}

.logo p {
    color: var(--text-light);
    transform: translateY(-5px);
    font-size: 14px;
    padding-left: 5px;
}

.nav-comp {
    display: flex;
    align-items: center;
    gap: 20px;
}

/* navlist */

.navlist {
    display: flex;
    align-items: center;
    gap: 20px;
    width: max-content;
    height: max-content;
}

.navlinks {
    position: relative;
    font-size: 18px;
    list-style: none;
    cursor: pointer;
    color: var(--text-dark);
    /* transition: var(--trsn3); */
}

.navlinks:hover {
    color: var(--primary);
}

/* buttons  */

.login-signup-btn {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-right: 20px;
}

.btn1 {
    font-size: 16px;
    background: var(--primary);
    border: none;
    border-radius: 6px;
    padding: 9px 12px;
    color: var(--white);
    cursor: pointer;
    transition: var(--trsn3);
}

.btn1:hover {
    background: var(--primary-dark);
}

/* Hamburger */

.hamburger {
    display: none;
    font-size: 25px;
    color: var(--primary);
    padding-right: 20px;
    cursor: pointer;
}

/* Dark mode icon */

.dark-mode-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.moon-icon {
    font-size: 22px;
    color: var(--primary);
    cursor: pointer;
}

/* sub-navbar */

.sub-navbar {
    position: sticky;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    width: 100vw;
    height: 45px;
    background: var(--bg-light);
    box-shadow: 0 2px 8px -2px var(--black-light);
    padding: 0 20px;
}

.social-media-icon {
    font-size: 27px;
    color: var(--primary);
    cursor: pointer;
}

/* Home section styling */

.home-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: max-content;
    padding-top: 200px;
}

/* home left section */

.home-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 20px 210px;
}

.home-left h1 {
    font-weight: 500;
    font-size: 35px;
    color: var(--text-dark);
}

.home-left h1>span {
    color: var(--text-dark);
}

.home-left h1>span>span {
    color: var(--primary);
}

/* Running text styling */

.home-left .animated-text {
    display: flex;
    gap: 10px;
    font-size: 25px;
    color: var(--text-dark);
}

.home-left .animated-text span {
    color: var(--primary);
}

.discription {
    line-height: 1.5rem;
    font-size: 16px;
    padding-top: 10px;
    color: var(--text-light);
}

/* home left buttons  */

.home-buttons {
    display: flex;
    gap: 20px;
    padding-top: 20px;
}

.home-buttons .btn2 {
    font-size: 14px;
    padding: 9px 12px;
    border: none;
    border-radius: 6px;
    color: var(--white);
    cursor: pointer;
}

.home-buttons .exp {
    background: var(--text-light);
}

.home-buttons .exp:hover {
    background: var(--text-dark);
}

body.dark-theme .home-buttons .exp:hover {
    color: var(--black-light);
}

.home-buttons .crt {
    background: var(--special-btn);
    color: var(--text-dark);
}

.home-buttons .crt:hover {
    background: var(--primary-light);
}

body.dark-theme .home-buttons .crt:hover {
    background: var(--text-light);
}


/* Project section styling */

.project-container {
    width: 100vw;
    height: max-content;
    padding: 40px 0;
}

.swiper-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
}

.swiper-slide {
    width: max-content;
    height: max-content;
    border-radius: 6px;
    object-fit: cover;
    box-shadow: 0 2px 2px var(--black-light);
}

.swiper-slide img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
}

.swiper-bullets {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-block: 30px;
}

.swiper-bullets .swiper-pagination-bullet-active {
    background: var(--primary);
}

body.dark-theme .swiper-bullets .swiper-pagination-bullet {
    background: var(--white);
}

/* Testimonial section styling */

.testimonial-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
}

.test-box{
    position: relative;
    width: 570px;
    height: 260px;
    border-radius: 3px;
    background: var(--testimonial);
    margin-top: 20px;
    margin-bottom: 40px;
}

body.dark-theme .test-box{
    background: var(--bg-light);
}

.quote-icon{
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 20px;
    color: var(--text-light);
}

.opinions{
    position: absolute;
    top: 60px;
    left: 20px;
    width: 100%;
    min-height: 170px;
}

.opinions p{
    line-height: 1.7rem;
    font-size: 17px;
    padding-right: 30px;
    color: var(--text-light);
}

.names{
    position: absolute;
    bottom: 20px;
    left: 40px;
    display: flex;
    flex-direction: column;
}

.names .name{
    font-size: 17px;
    color: var(--text-dark);
}

.names .profesion{
    font-size: 14px;
    color: var(--text-light);
}

/* Footer section styling */

.footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    height: 80px;
    background: var(--bg-light);
}

.copyRight{
    color: var(--text-light);
    font-size: 16px;
}

.footer .icons{
    display: flex;
    gap: 10px;
    padding-right: 30px;
}


/* Responsive part  */

@media screen and (max-width : 1000px) {
    /* Navbar  */

    /* Logo  */
    .logo h2 {
        font-size: 28px;
    }

    .logo p {
        font-size: 13px;
    }

    .nav-comp {
        gap: 19px;
    }

    /* navlist */

    .navlist {
        gap: 19px;
    }

    .navlinks {
        font-size: 17px;
    }

    /* buttons  */

    .login-signup-btn {
        padding-right: 19px;
    }

    .btn1 {
        font-size: 17px;
    }

    /* home  */
    .home-left {
        padding: 0 160px;
    }

    /* testimonials */

    .testimonial-container{
        gap: 20px;
    }

    .test-box{
        margin-top: 0px;
        margin-bottom: 0px;
    }

}

@media screen and (max-width : 968px) {
    /* Home  */

    .home-left {
        align-items: center;
        width: 100%;
        text-align: center;
        padding: 0 100px;
    }
}

@media screen and (max-width : 850px) {
    /* Navbar  */

    /* Logo  */
    .logo h2 {
        font-size: 27px;
    }

    .logo p {
        font-size: 12px;
    }

    .nav-comp {
        gap: 18px;
    }

    /* navlist */

    .navlist {
        gap: 18px;
    }

    .navlinks {
        font-size: 16px;
    }

    /* buttons  */

    .login-signup-btn {
        padding-right: 18px;
    }

    .btn1 {
        font-size: 16px;
    }
}

@media screen and (max-width : 750px) {
    /* Navbar  */

    /* Logo  */
    .logo h2 {
        font-size: 26px;
    }

    .logo p {
        font-size: 11px;
    }

    .nav-comp {
        gap: 17px;
    }

    /* navlist */

    .navlist {
        gap: 17px;
    }

    .navlinks {
        font-size: 15px;
    }

    /* buttons  */

    .login-signup-btn {
        padding-right: 17px;
    }

    .btn1 {
        font-size: 15px;
        padding: 5px 8px;
    }
}

@media screen and (max-width : 650px) {

    /* Navbar  */

    /* Mobile version styling */

    .nav-comp {
        display: none;
        position: absolute;
        top: 50px;
        right: 42px;
        width: max-content;
        height: max-content;
        background: var(--bg);
        flex-direction: column;
        padding: 20px 40px;
        border-radius: 6px;
        box-shadow: 0 1px 2px 1px var(--black-light);
        z-index: 9999;
    }

    .mobile-version {
        display: block;
    }

    .nav-comp .navlist {
        flex-direction: column;
    }

    .nav-comp .login-signup-btn {
        flex-direction: column;
        padding-right: 0;
        padding-top: 10px;
    }

    .nav-comp .login-signup-btn .btn1 {
        font-size: 17px;
    }

    .hamburger {
        display: block;
    }

    /* navlist */

    .navlinks {
        font-size: 17px;
    }

    /* dark mode  */

    .dark-mode-icon {
        padding: 10px 0;
    }

    /* Home  */

    .home-left {
        padding: 0 60px;
    }

    /* testimonials */

    .test-box{
        width: 480px;
    }

    .quote-icon{
        font-size: 18px;
    }

    .opinions p{
        font-size: 15px;
    }
    
    .names .name{
        font-size: 15px;
    }

    .names .profesion{
        font-size: 13px;
    }

    /* footer  */
    .footer{
        justify-content: center;
        margin-top: 50px;
    }

    .footer .logo{
        display: none;
    }

    .footer .icons{
        display: none;
    }
}


@media screen and (max-width : 550px) {
    /* Navbar  */

    /* Logo  */
    .logo h2 {
        font-size: 24px;
    }

    .logo p {
        font-size: 10px;
    }

    /* sub-navbar  */
    .sub-navbar {
        gap: 30px;
    }

    /* home  */

    .home-left h1 {
        font-size: 28px;
    }

    .home-left .animated-text {
        font-size: 20px;
    }

    .home-left .discription {
        line-height: 1.3rem;
        font-size: 14px;
    }

    /* testimonials */

    .test-box{
        width: 440px;
    }

    .quote-icon{
        font-size: 16px;
    }

    .opinions p{
        font-size: 14px;
    }
    
    .names .name{
        font-size: 14px;
    }

    .names .profesion{
        font-size: 12px;
    }
}

@media screen and (max-width : 450px){
    
    /* testimonials */

    .test-box{
        width: 350px;
        height: 290px;
    }

    .quote-icon{
        font-size: 15px;
    }

    .opinions p{
        font-size: 13px;
    }
    
    .names .name{
        font-size: 13px;
    }

    .names .profesion{
        font-size: 11px;
    }

}

@media screen and (max-width : 400px) {
    /* buttons  */

    .nav-comp .login-signup-btn .btn1 {
        font-size: 15px;
    }

    /* navlist */

    .navlinks {
        font-size: 15px;
    }

    /* home  */

    .home-left {
        padding: 0 10px;
    }

    .home-left h1 {
        font-size: 28px;
    }

    .home-left .animated-text {
        font-size: 18px;
    }

    
    
}

@media screen and (max-width : 350px){

    /* testimonials */

    .test-box{
        width: 250px;
        height: 350px;
    }

    .quote-icon{
        font-size: 15px;
    }

    .opinions p{
        font-size: 13px;
    }
    
    .names .name{
        font-size: 13px;
    }

    .names .profesion{
        font-size: 11px;
    }

}

@media screen and (max-width : 300px) {

    /* sub-navbar  */
    .sub-navbar {
        gap: 25px;
    }

}